import React from 'react';
import { Link } from 'react-router-dom';
import {
  CompanyInfo,
  FooterContainer,
  FooterContent,
  FooterColumn,
  LinksContainer,
} from './styles';
import devyxSloganLogoImg from '../../../assets/sloganWithLogoOnRight.svg';

const Footer: React.FC = () => (
  <FooterContainer>
    <FooterContent>
      <CompanyInfo>
        <Link to="/">
          <img src={devyxSloganLogoImg} alt="Logo" />
        </Link>
        <span>
          <span />
          Devyx &reg; {new Date().getFullYear()} <br />
          <span>Todos os direitos reservados.</span>
        </span>
      </CompanyInfo>

      <LinksContainer>
        <FooterColumn>
          <div>
            <Link to="/terms">Termos de uso</Link>
          </div>
        </FooterColumn>

        <FooterColumn>
          <div>
            <Link to="/privacy">Políticas de privacidade</Link>
          </div>
        </FooterColumn>

        <FooterColumn>
          <div>
            <Link to="/help">Ajuda</Link>
          </div>
        </FooterColumn>
      </LinksContainer>
    </FooterContent>
  </FooterContainer>
);

export default Footer;
