import React from 'react';
import {
  DefaultNotification,
  IDefaultNotificationProps,
} from '../../DefaultNotification';
import { HelpText } from '../../DefaultNotification/styles';
import { ContainerLines, InvalidLines, ValidLines } from './styles';

export interface IFileNotificationProps extends IDefaultNotificationProps {
  stats: 'success' | 'failure';
  error: string | null;
  file_name: string;
  valid_lines: string | null;
  error_lines: string | null;
}

export const FileNotification: React.FC<IFileNotificationProps> = ({
  file_name,
  stats,
  error,
  valid_lines,
  error_lines,
  ...rest
}) => {
  const success = 'success';
  return (
    <DefaultNotification {...rest}>
      <HelpText>Arquivo: {file_name}</HelpText>
      {stats === success ? (
        <ContainerLines>
          {valid_lines && (
            <ValidLines>Linhas válidas: {valid_lines}</ValidLines>
          )}
          {error_lines && (
            <InvalidLines>Linhas inválidas: {error_lines}</InvalidLines>
          )}
        </ContainerLines>
      ) : (
        <p>{error && <InvalidLines>{error}</InvalidLines>}</p>
      )}
    </DefaultNotification>
  );
};
