import { useLocation } from 'react-router-dom';
import { useState } from 'react';

interface IPath {
  from: { pathname: any };
}

function useRedirectTo(): IPath {
  const [currentLocationState, setCurrentLocationState] = useState<IPath>({
    from: { pathname: '/' },
  });
  const location = useLocation<IPath>();
  if (location.state) setCurrentLocationState(location.state);

  return currentLocationState;
}

export default useRedirectTo;
