/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import { format, parseISO } from 'date-fns';
import { StringHelpers } from 'devyx-ui';

interface IComponentProps {
  value: any;
  withHour?: boolean;
  interval?: 'month' | 'year';
}

export const DateColumnFormatter: React.FC<IComponentProps> = ({
  value,
  withHour = false,
}) => {
  return !withHour ? (
    value ? (
      <span style={{ justifyContent: 'flex-start' }}>
        {format(parseISO(value), 'dd/MM/yyyy')}
      </span>
    ) : (
      <span>-</span>
    )
  ) : value ? (
    <span style={{ justifyContent: 'flex-start' }}>
      {format(parseISO(value), "dd/MM/yyyy 'às' HH:mm")}
    </span>
  ) : (
    <span>-</span>
  );
};

DateColumnFormatter.defaultProps = {
  withHour: false,
};

export const PriceColumnFormatter = ({
  value,
  interval,
}: IComponentProps): JSX.Element => {
  if (!value.length) return <>-</>;

  const price = value.find(
    (el: any) => el.interval === interval,
  )?.unit_amount_decimal;

  return price ? <>{StringHelpers.formatCurrency(price)}</> : <>-</>;
};
