import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled.div`
  @media (max-width: 980px) {
    div:first-child > div {
      position: fixed;
      left: 0;
      top: 70px;
      width: 100%;
      margin-top: 0px !important;

      ::before {
        display: none;
      }
    }
  }
`;

export const UserMainInfos = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  margin: 0 auto 24px auto;

  min-width: 240px;

  img {
    height: 80px;
    width: 80px;
    border-radius: 50%;
    margin: 4px auto 16px auto;
  }
`;

export const UserName = styled.span`
  font-weight: 600;
  font-size: 16px;
  margin: 0 auto;
`;

export const UserEmail = styled.span`
  font-weight: 300;
  font-size: 14px;
  margin: 6px auto 0 auto;
`;

export const UserCompanyInfos = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 2px;
  margin-bottom: 24px;
`;

export const Footer = styled.div`
  display: grid;
  grid-template-columns: 38% 1fr;
  grid-gap: 15px;

  @media (max-width: 700px) {
    grid-template-columns: 1fr;
  }
`;

export const Label = styled.span`
  font-size: 16px;
  font-weight: 500;

  margin-right: 6px;
`;

export const TextItem = styled.span`
  display: flex;
  padding: 6px 15px;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.5px;
  background: rgb(225, 225, 230);
`;

export const Item = styled(Link)`
  display: flex;
  flex-direction: row-reverse;
  -webkit-box-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  align-items: center;
  font-size: 16px;
  padding: 10px 0px;
  transition: background 0.2s ease 0s;
  cursor: pointer;
  background: transparent;
  border: none;
  width: 100%;
  text-decoration: none;

  span {
    margin-left: 3px;
    color: var(--black);
  }

  &:hover {
    background: rgb(225, 225, 230);
  }

  div {
    position: relative;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    width: 20px;
  }

  @media (max-width: 600px) {
    font-size: 14px;
    padding: 10px 15px;
  }
`;
