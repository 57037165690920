import styled from 'styled-components';
import { HelpText } from '../../DefaultNotification/styles';

export const ContainerLines = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 920px) {
    flex-direction: column;
  }
`;

export const ValidLines = styled(HelpText)`
  margin-top: 0.2rem;
  color: var(--green);
`;

export const InvalidLines = styled(HelpText)`
  margin-top: 0.2rem;
  color: var(--red);
`;
