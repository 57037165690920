import { useKeycloak } from '@react-keycloak/web';
import {
  createContext,
  ReactElement,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { toast } from 'react-toastify';
import useFetch, { CachePolicies, IncomingOptions } from 'use-http';

interface IAuthProviderProps {
  children: ReactNode;
}

interface IAuthResponseData {
  id: string;
  name: string;
  email: string;
  phone_number: string;
  avatar: boolean;
  is_active: boolean;
  marketing: string; // JSON string
  notifications: string; // JSON string
  updated_at: string;
  created_at: string;
  categoryCompanyLevelUser: unknown;
}

// parsed JSON string
interface INotifications {
  zoug?: boolean;
  trader?: boolean;
  buildr?: boolean;
}

// parsed JSON string
interface IMarketing {
  zoug?: boolean;
  trader?: boolean;
  buildr?: boolean;
}

interface IAuthData
  extends Omit<IAuthResponseData, 'marketing' | 'notifications'> {
  notifications: INotifications;
  marketing: IMarketing;
  keycloackId?: string;
}

interface IAuthContextData {
  authData: IAuthData;
  updateAuthData: () => Promise<void>;
}

const AuthContext = createContext<IAuthContextData>({} as IAuthContextData);

export const AuthProvider = ({
  children,
}: IAuthProviderProps): ReactElement => {
  const [authData, setAuthData] = useState<IAuthData>({} as IAuthData);

  const {
    keycloak: { tokenParsed, authenticated, token },
  } = useKeycloak();

  const { get, response } = useFetch(process.env.REACT_APP_BACKEND_URL, {
    cachePolicy: CachePolicies.CACHE_AND_NETWORK,
    suspense: true,
    interceptors: {
      request: async ({ options }) => {
        if (authenticated) {
          options.headers = {
            authorization: `Bearer ${token}`,
          };
        }
        return options;
      },
    },
  } as IncomingOptions);

  const updateAuthData = useCallback(async () => {
    await get('/auth/users/me');

    if (response.ok) {
      const responseData = response.data as IAuthResponseData;
      const {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        categoryCompanyLevelUser,
        marketing,
        notifications,
        ...newAuthData
      } = responseData;

      const notificationsParsed = {
        zoug: false,
        buildr: false,
        trader: false,
      } as INotifications;

      const marketingParsed = {
        zoug: false,
        buildr: false,
        trader: false,
      } as IMarketing;

      try {
        const newNotificationsParsed =
          typeof notifications === 'string'
            ? (JSON.parse(notifications) as INotifications)
            : notifications;
        const newMarketingParsed =
          typeof notifications === 'string'
            ? (JSON.parse(marketing) as IMarketing)
            : marketing;
        Object.assign(notificationsParsed, newNotificationsParsed);
        Object.assign(marketingParsed, newMarketingParsed);
      } catch (error) {
        if (error instanceof SyntaxError) {
          console.warn(notifications, error.name, error.message);
        }
      }

      setAuthData({
        notifications: notificationsParsed,
        marketing: marketingParsed,
        keycloackId: tokenParsed?.sub,
        ...newAuthData,
      } as IAuthData);
    } else {
      // response.ok === false
      toast.error(
        response.status < 500
          ? 'Falha ao autenticar usuário logado! Tente novamente mais tarde'
          : 'Serviço indisponível! Contate um administrador.',
      );
    }
  }, [get, response, tokenParsed?.sub]);

  useEffect(() => {
    updateAuthData();
  }, [updateAuthData]);

  const memoizedContextValue = useMemo(
    () => ({ authData, updateAuthData }),
    [authData, updateAuthData],
  );

  return (
    <AuthContext.Provider value={memoizedContextValue}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): IAuthContextData => {
  return useContext(AuthContext);
};

export default AuthProvider;
