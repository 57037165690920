import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
} from 'date-fns';
import { IoNotificationsOutline } from 'react-icons/io5';
import { toast } from 'react-toastify';
import { IParsedContentNotification } from '../DropdownNotification';
import { NotificationAlertContainer } from './NotificationsList/styles';

export interface INotification {
  _id: string;
  content: string;
  contentParsed: IParsedContentNotification;
  created_at: string;
  read_at: string;
  isOpen: boolean;
  isRead: boolean;
}

export type NotificationsTypeNames =
  | 'create-subscription'
  | 'update-subscription'
  | 'expired-invoice'
  | 'file'
  | 'default';

type NotificationsTypeMessage = {
  [key in NotificationsTypeNames]: string;
};

const notificationsAlertMessages: NotificationsTypeMessage = {
  'create-subscription': 'A companhia realizou uma assinatura no plano',
  'update-subscription': 'A assinatura da companhia foi alterada.',
  'expired-invoice': 'A companhia atrasou o pagamento de uma fatura.',
  file: 'Arquivo',
  default: 'Notificação recebida',
};

export const translatedNotificationsTypes: NotificationsTypeMessage = {
  'create-subscription': 'Assinatura criada!',
  'update-subscription': 'Assinatura alterada!',
  'expired-invoice': 'Pagamento atrasado!',
  file: 'Arquivo',
  default: 'Notificação',
};

export const MAX_NOTIFICATIONS_IN_DROPDOWN = 5;

export const notificationAlert = (notification: string): boolean => {
  try {
    const parsedNotification = JSON.parse(
      notification,
    ) as IParsedContentNotification;

    if (
      Object.keys(notificationsAlertMessages).includes(parsedNotification.type)
    ) {
      toast(
        <NotificationAlertContainer>
          <IoNotificationsOutline size={30} color="var(--green)" />
          {notificationsAlertMessages[parsedNotification.type]}
        </NotificationAlertContainer>,
        {
          closeButton: true,
          className: 'toast-notification',
          autoClose: 7000,
          hideProgressBar: true,
          progress: undefined,
          pauseOnFocusLoss: false,
        },
      );
      return true;
    }

    toast(
      <NotificationAlertContainer>
        <IoNotificationsOutline size={30} color="var(--green)" />
        {notificationsAlertMessages.default}
      </NotificationAlertContainer>,
      {
        closeButton: true,
        className: 'toast-notification',
        autoClose: 7000,
        hideProgressBar: true,
        progress: undefined,
        pauseOnFocusLoss: false,
      },
    );

    return false;
  } catch (err) {
    console.error('Notification parse failed', err);
    return false;
  }
};

export function calculateDifferenceTime(createdAt: string, now: Date): string {
  const oldTime = new Date(createdAt);
  const passedSeconds = differenceInSeconds(now, oldTime, {
    roundingMethod: 'ceil',
  });

  let time = 'Há ';

  if (passedSeconds < 60) {
    time += `${passedSeconds} s`;
    if (passedSeconds < 0) time = 'Agora';
  }

  if (passedSeconds > 60 && passedSeconds < 3600) {
    time += `${differenceInMinutes(now, oldTime)} min`;
  }

  if (passedSeconds > 3600 && passedSeconds < 86400) {
    time += `${differenceInHours(now, oldTime)} h`;
  }

  if (passedSeconds > 86400) {
    time += `${differenceInDays(now, oldTime)} dia(s)`;
  }

  return `${time}`;
}
