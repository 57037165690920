/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect } from 'react';
import Keycloak from 'keycloak-js';
import { AuthClientInitOptions } from '@react-keycloak/core';
import { useKeycloak } from '@react-keycloak/web';
import * as ConfigKeycloak from '../keycloak.json';
import SplashScreen from '../components/layout/SplashScreen';

export const keycloakInstance = new Keycloak({
  url: process.env.REACT_APP_SSO_URL ?? ConfigKeycloak['auth-server-url'],
  realm: ConfigKeycloak.realm,
  clientId: ConfigKeycloak.resource,
});

export const keycloakConfig: AuthClientInitOptions = {
  onLoad: 'check-sso',
};

export const IsKeycloakInitialized: React.FC = ({ children }) => {
  const { keycloak, initialized } = useKeycloak();
  useEffect(() => {
    if (keycloak && initialized) {
      keycloak.onTokenExpired = async () => keycloak.updateToken(600);
    }
    return () => {
      if (keycloak) keycloak.onTokenExpired = () => null;
    };
  }, [initialized, keycloak]);
  return <>{!initialized ? <SplashScreen /> : children}</>;
};

export const userLevels: { [key: string]: string } = {
  '1': 'system',
  '2': 'partner',
  '3': 'client',
  '4': 'vendor',
};
