import * as React from 'react';
import { Dropdown } from 'devyx-ui';
import { FiBarChart2, FiSettings } from 'react-icons/fi';
import { GiOrganigram } from 'react-icons/gi';
import { RiListSettingsLine } from 'react-icons/ri';
import { useHistory } from 'react-router-dom';
import { Container, DropdownConfigsItem } from './styles';

const DropdownConfigs: React.FC = () => {
  const {
    location: { key },
  } = useHistory();

  return (
    <Dropdown
      triggerToClose={key}
      toggle={<FiSettings size={24} color="var(--black)" />}
      extraDropdownStyles={{ marginTop: '22px' }}
    >
      <Container>
        <DropdownConfigsItem to="/configurations/levels">
          <FiBarChart2 />
          Níveis
        </DropdownConfigsItem>
        <DropdownConfigsItem to="/configurations/categories">
          <GiOrganigram />
          Categorias
        </DropdownConfigsItem>
        <DropdownConfigsItem to="/configurations/options">
          <RiListSettingsLine />
          Opções
        </DropdownConfigsItem>
      </Container>
    </Dropdown>
  );
};

export default DropdownConfigs;
