import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 14px;
  align-items: left;
`;

export const DropdownMenuItem = styled(Link)`
  display: grid;
  grid-template-columns: 30px 1fr;

  text-decoration: none;
  font-size: 16px;
  color: var(--black);
  transition: 0.5s;
  font-weight: 500;
  opacity: 0.9;

  &:hover {
    color: var(--black);
    opacity: 0.6;
  }

  svg {
    width: 20px;
    height: 20px;

    &:hover {
      opacity: 0.6;
    }
  }
`;
