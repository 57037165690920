import { ToastContainer } from 'react-toastify';
import styled from 'styled-components';

export const StyledToastContainer = styled(ToastContainer).attrs({
  toastClassName: 'toast',
  toastClassNameError: 'Toastify__toast--error',
  bodyClassName: 'body',
})`
  /* .toast is passed to toastClassName */
  .toast {
    background-color: var(--white);
  }
  /* .Toastify__toast--error {
    background-color: var(--red);
  } */
  button[aria-label='close'] {
    display: none;
  }
`;
