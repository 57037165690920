import { useKeycloak } from '@react-keycloak/web';
import React, { useCallback } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

const PrivateRoute: React.FC<RouteProps> = props => {
  const { component: Component, children, ...rest } = props;
  const { keycloak } = useKeycloak();

  const render = useCallback(
    renderProps => {
      if (keycloak.authenticated) {
        return !Component ? children : <Component {...renderProps} />;
      }

      return (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: renderProps.location },
          }}
        />
      );
    },
    [keycloak.authenticated, Component, children],
  );

  return <Route {...rest} render={render} />;
};

export default PrivateRoute;
