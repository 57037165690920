import React from 'react';
import { FaEnvelopeOpen } from 'react-icons/fa';
import { INotificationsProps } from '../../DropdownNotification';
import { NotificationItem } from '../NotificationsItem';
import { ItemEmpty, Notifications } from './styles';

interface INotificationListProps {
  notifications: Array<INotificationsProps>;
  onRead: (id: any) => void;
  currentTime: Date;
}

export const NotificationsList: React.FC<INotificationListProps> = ({
  notifications,
  onRead,
  currentTime,
}) => {
  return (
    <Notifications>
      {notifications.length ? (
        notifications.map((notification: INotificationsProps) => {
          return (
            <NotificationItem
              // eslint-disable-next-line no-underscore-dangle
              key={notification._id}
              openAndCloseNotification={onRead}
              currentTime={currentTime}
              notification={notification}
            />
          );
        })
      ) : (
        <ItemEmpty>
          <FaEnvelopeOpen />
          Não há nada por aqui
        </ItemEmpty>
      )}
    </Notifications>
  );
};
