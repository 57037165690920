import styled from 'styled-components';

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const NotificationDate = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.7rem;
  font-size: 12px;
  svg {
    display: inline;
    margin-right: 3px;
  }
`;

export const HelpText = styled.small`
  margin-top: 0.5rem;
  font-weight: 600;
  color: rgba(59, 58, 52, 0.9);
`;

export const ItemSubtitle = styled.div`
  font-size: 13px;
  font-family: Roboto;
`;

export const ItemText = styled.p`
  font-size: 13px;
  font-family: Roboto;
`;
