/* eslint-disable consistent-return */
/* eslint-disable default-case */
import styled from 'styled-components';

export const DropdownHeader = styled.div`
  display: flex;
  align-items: flex-end;
  box-shadow: 0 4px 2px -2px gray;
  margin-bottom: 0.5rem;
  padding-bottom: 8px;
  width: 350px;
  @media (max-width: 700px) {
    flex-direction: column;
    width: 200px;
    height: 110px;
  }
`;

export const DropdownHeaderTitle = styled.div`
  padding-top: 5px;
  font-size: 26px;
  @media (max-width: 700px) {
    flex-direction: column;
    padding-top: 10px;
    width: 200px;
    height: 110px;
  }
`;

export const Count = styled.div`
  position: absolute;
  right: -2px;
  top: -2px;
  background-color: var(--blue);
  border-radius: 12px;
  width: 20px;
  height: 20px;
  font-size: 14px;
  color: var(--white);
  @media (max-width: 980px) {
    right: -8px;
    top: -6px;
  }
`;

export const DropdownHeaderActionsItem = styled.div`
  font-size: 13px;
  padding: 5px 0px 5px 50px;
  font-style: italic;
  cursor: pointer;
  color: var(--green);
  align-items: center;
  svg {
    margin-right: 5px;
  }
  @media (max-width: 700px) {
    padding: 5px 0px;
  }
  :first-child {
    color: var(--blue);
  }
`;
