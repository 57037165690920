import { useKeycloak } from '@react-keycloak/web';
import { CrudContext } from 'devyx-ui';
import React, { lazy, useEffect, useState } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { CachePolicies, Provider as HttpProvider } from 'use-http';
import Footer from '../components/layout/Footer';
import Header from '../components/layout/Header';
import Login from '../pages/Auth/Login';
import Logout from '../pages/Auth/Logout';
import PrivateRoute from './PrivateRoute';

const Home = lazy(() => import('../pages/Dashboard'));
const Users = lazy(() => import('../modules/Users/Users.routes'));
const CompaniesPage = lazy(
  () => import('../modules/Companies/pages/CompaniesPage'),
);
const Profile = lazy(() => import('../pages/Profile'));
const NotFoundPage = lazy(() => import('../pages/NotFound'));
const ProductsPage = lazy(() => import('../modules/Products/Products.routes'));
const ReportsPage = lazy(() => import('../modules/Reports/Reports.routes'));
const ConfigurationsPage = lazy(
  () => import('../modules/Configurations/Configurations.routes'),
);
const Terms = lazy(() => import('../pages/UseTerms'));

const Routes: React.FC = () => {
  const [optionsProvider, setOptionsProvider] = useState<any | null>();
  const { keycloak } = useKeycloak();
  const { push } = useHistory();

  const { setOpenAddCrudModal } = CrudContext.useCrud();

  useEffect(() => {
    if (keycloak?.authenticated && keycloak?.token) {
      setOptionsProvider({
        cachePolicy: CachePolicies.CACHE_AND_NETWORK,
        interceptors: {
          request: async ({ options, url }: any) => {
            const newOptions = options;
            if (keycloak.authenticated) {
              if (!url.includes('ibge') && !url.includes('viacep')) {
                newOptions.headers.authorization = `Bearer ${keycloak.token}`;
              }
            }
            return newOptions;
          },
          response: ({ response }: any) => {
            const res = response;

            if (res.status === 401) push('/logout');

            return res;
          },
        },
      });
    }
  }, [keycloak, push]);

  return (
    <HttpProvider
      url={process.env.REACT_APP_BACKEND_URL}
      options={optionsProvider}
    >
      {optionsProvider && <Header />}
      <div style={{ maxWidth: '1120px', margin: '0 auto 5rem' }}>
        <Switch>
          <Route path="/login" exact component={Login} />

          <PrivateRoute path="/" exact component={Home} />

          <PrivateRoute path="/users">
            <Users setOpenAddCrudModal={setOpenAddCrudModal} />
          </PrivateRoute>

          <PrivateRoute path="/companies">
            <CompaniesPage setOpenAddCrudModal={setOpenAddCrudModal} />
          </PrivateRoute>

          <PrivateRoute path="/configurations">
            <ConfigurationsPage setOpenAddCrudModal={setOpenAddCrudModal} />
          </PrivateRoute>

          <PrivateRoute path="/products">
            <ProductsPage setOpenAddCrudModal={setOpenAddCrudModal} />
          </PrivateRoute>

          <PrivateRoute path="/reports" component={ReportsPage} />

          <PrivateRoute path="/profile" component={Profile} />

          <PrivateRoute path="/logout" component={Logout} />

          <PrivateRoute path="/terms" component={Terms} />

          <PrivateRoute component={NotFoundPage} />
        </Switch>
      </div>
      <Footer />
    </HttpProvider>
  );
};

export default Routes;
