import styled from 'styled-components';

export const FooterContainer = styled.footer`
  width: 100%;
  display: flex;
  justify-content: center;
  height: 80px;
`;

export const FooterContent = styled.div`
  display: flex;
  width: 100%;
  max-width: calc(1500px + 200px);
  height: 80px;
  bottom: 0;
  justify-content: space-between;
  align-items: center;
  padding: 20px 100px;
  position: absolute;

  @media (max-width: 900px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const LinksContainer = styled.div`
  display: flex;
  column-gap: 50px;
  align-items: center;

  @media (max-width: 900px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 20px;
  }
`;

export const CompanyInfo = styled.div`
  display: flex;
  align-items: center;
  column-gap: 40px;

  > span {
    font-size: 16px;
  }

  img {
    width: 200px;
  }

  @media (max-width: 1050px) {
    justify-content: center;
    img {
      width: 190px;
    }

    > span {
      font-size: 15px;
    }
  }

  @media (max-width: 900px) {
    flex-direction: column;
    align-items: center;

    row-gap: 8px;

    > span {
      display: none;
    }

    img {
      width: 180px;
    }
  }
`;

export const FooterColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-content: space-between;

  div {
    display: flex;
    flex-direction: column;

    a {
      color: var(--black);
      text-decoration: none;
      font-size: 16px;
      transition: 0.3s;

      &:hover {
        opacity: 0.6;
      }
    }
  }

  @media (max-width: 1050px) {
    a {
      font-size: 15px;
    }
  }

  @media (max-width: 900px) {
    display: flex;
    width: 100%;
    align-items: center;

    margin-top: 1rem;

    div {
      align-items: center;

      a {
        font-size: 14px;
      }
    }
  }
`;
