import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { FiSettings, FiLogOut } from 'react-icons/fi';
import { Dropdown, Button, UserAvatar } from 'devyx-ui';
import {
  Container,
  UserMainInfos,
  Footer,
  UserName,
  UserEmail,
} from './styles';
import blank from '../../../assets/blank.png';

interface IDropdownProfileProps {
  toggle: React.ReactNode;
  user: {
    avatar: boolean;
    name: string;
    email: string;
    avatarURL: string;
  };
}

const DropdownProfile: React.FC<IDropdownProfileProps> = ({ toggle, user }) => {
  const history = useHistory();

  const handleClickUserAccountConfig = useCallback(() => {
    history.push('/profile');
  }, [history]);

  const handleClickLogout = useCallback(() => {
    history.push('/logout');
  }, [history]);

  return (
    <Container>
      <Dropdown
        triggerToClose={history.location.key}
        toggle={toggle}
        extraDropdownStyles={{ marginTop: '22px', cursor: 'default' }}
      >
        <UserMainInfos>
          <UserAvatar
            defaultImage={blank}
            hasAvatar={user.avatar}
            imageUrl={user.avatarURL}
          />
          <UserName>{user.name}</UserName>
          <UserEmail>{user.email}</UserEmail>
        </UserMainInfos>

        <Footer>
          <Button
            onClick={() => {
              handleClickUserAccountConfig();
            }}
            color="black-outlined"
          >
            <FiSettings />
            Conta
          </Button>
          <Button
            color="red-outlined"
            onClick={() => {
              handleClickLogout();
            }}
          >
            <FiLogOut />
            Logout
          </Button>
        </Footer>
      </Dropdown>
    </Container>
  );
};

export default DropdownProfile;
