import * as React from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { Redirect } from 'react-router-dom';
import useRedirectTo from '../../helpers/location';

const Logout: React.FC = () => {
  const currentLocationState = useRedirectTo();

  const { keycloak } = useKeycloak();

  if (keycloak?.authenticated) keycloak?.logout();

  return <Redirect to={currentLocationState.from.pathname} />;
};

export default Logout;
