export type InvoiceNames =
  | 'paid'
  | 'pending'
  | 'canceled'
  | 'in_analysis'
  | 'draft'
  | 'partially_paid'
  | 'refunded'
  | 'expired'
  | 'in_protest'
  | 'chargeback';

type TranslatedInvoiceStatus = {
  [key in InvoiceNames]: string;
};

export const statusMessages: TranslatedInvoiceStatus = {
  paid: 'Fatura paga!',
  pending: 'A fatura ainda não foi paga',
  canceled: 'Fatura cancelada.',
  in_analysis: 'Fatura está em análise',
  draft: 'A fatura ainda não foi gerada, apenas os dados foram salvos',
  partially_paid: 'Fatura parcialmente paga.',
  refunded: 'Fatura reembolsada.',
  expired: 'A cobrança atingiu o tempo limite após o vencimento e expirou.',
  in_protest: 'Não reconhecimento da compra.',
  chargeback: 'Valor da fatura foi estornado.',
};
