/* eslint-disable no-underscore-dangle */
import React from 'react';
import { AiOutlineArrowDown, AiOutlineArrowUp } from 'react-icons/ai';
import {
  calculateDifferenceTime,
  CreateSubscriptionNotification,
  ExpiredInvoicesNotification,
  FileNotification,
  NotificationsTypeNames,
  translatedNotificationsTypes,
} from '..';
import { INotificationsProps } from '../../DropdownNotification';
import { IExpiredInvoiceNotificationProps } from '../components/ExpiredInvoiceNotification';
import { IFileNotificationProps } from '../components/FileNotification';
import { UpdateSubscriptionNotification } from '../components/UpdateSubscriptionNotification';
import {
  DefaultNotification,
  IDefaultNotificationProps,
} from '../DefaultNotification';
import {
  ItemContent,
  Item,
  ItemHeader,
  BulletUnreadItem,
  ItemTitle,
  ItemBody,
} from '../NotificationsList/styles';

interface INotificationItem {
  notification: INotificationsProps;
  currentTime: Date;
  openAndCloseNotification: (id: string) => void;
}

type PropsNotifications = IDefaultNotificationProps;

interface INotificationTypes {
  [key: string]: (
    props: PropsNotifications,
    children?: React.ReactNode,
  ) => React.ReactNode;
}

export const NotificationsTypes: INotificationTypes = {
  'create-subscription': (props: PropsNotifications) => {
    return <CreateSubscriptionNotification {...props} />;
  },
  'update-subscription': (props: PropsNotifications) => {
    return <UpdateSubscriptionNotification {...props} />;
  },
  'expired-invoice': (props: PropsNotifications) => {
    return (
      <ExpiredInvoicesNotification
        {...(props as IExpiredInvoiceNotificationProps)}
      />
    );
  },
  file: (props: PropsNotifications) => {
    return <FileNotification {...(props as IFileNotificationProps)} />;
  },
  default: (props: PropsNotifications) => {
    return <DefaultNotification {...(props as IFileNotificationProps)} />;
  },
};

export const NotificationItem: React.FC<INotificationItem> = ({
  notification,
  currentTime,
  openAndCloseNotification,
}) => {
  const notificationParsed =
    typeof notification.content === 'object'
      ? notification.content
      : JSON.parse(notification.content);

  if (!(notificationParsed.type in NotificationsTypes)) {
    notificationParsed.type = 'default';
  }

  return (
    <Item key={notification._id}>
      {!notification.isRead && <BulletUnreadItem />}
      <ItemContent
        isRead={notification.isRead}
        onClick={() => openAndCloseNotification(notification._id)}
      >
        <ItemHeader>
          <ItemTitle>
            {
              translatedNotificationsTypes[
                notificationParsed.type as NotificationsTypeNames
              ]
            }
          </ItemTitle>
          <small>
            {calculateDifferenceTime(notification.created_at, currentTime)}
          </small>
        </ItemHeader>
        <ItemBody>
          {NotificationsTypes[
            notificationParsed.type as NotificationsTypeNames
          ]({
            created_at: notification.created_at,
            isOpen: notification.isOpen,
            ...notificationParsed,
          })}
          {notification.isOpen ? <AiOutlineArrowUp /> : <AiOutlineArrowDown />}
        </ItemBody>
      </ItemContent>
    </Item>
  );
};
