import React from 'react';
import { Dropdown } from 'devyx-ui';
import { useHistory } from 'react-router-dom';
import { Container, DropdownMenuItem } from './styles';

interface IDropdownMenuProps {
  title: string;
}

const DropdownMenuBase: React.FC<IDropdownMenuProps> = ({
  title,
  children,
}) => {
  const {
    location: { key },
  } = useHistory();

  return (
    <Dropdown dropType="menu" toggle={title} triggerToClose={key}>
      <Container>{children}</Container>
    </Dropdown>
  );
};

const DropdownMenu = {
  Base: DropdownMenuBase,
  Item: DropdownMenuItem,
};

export default DropdownMenu;
