import React from 'react';
import {
  DefaultNotification,
  IDefaultNotificationProps,
} from '../../DefaultNotification';

export const CreateSubscriptionNotification: React.FC<
  IDefaultNotificationProps
> = ({ ...notification }) => {
  return <DefaultNotification {...notification} />;
};
