/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
import { Dropdown, StringHelpers } from 'devyx-ui';
import React, { useCallback, useEffect, useState } from 'react';
import { io } from 'socket.io-client';
import { BsBell } from 'react-icons/bs';
import { AiOutlineCheck } from 'react-icons/ai';

import useFetch from 'use-http';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import {
  DropdownHeader,
  DropdownHeaderTitle,
  DropdownHeaderActionsItem,
  Count,
} from './styles';
import {
  MAX_NOTIFICATIONS_IN_DROPDOWN,
  notificationAlert,
  NotificationsTypeNames,
} from '../Notifications';
import { NotificationsList } from '../Notifications/NotificationsList';

export interface INotificationsProps {
  _id: string;
  channel: string;
  user_id: string;
  software_id?: string | null;
  content: string;
  company_id?: string;
  read_at: string | null;
  deleted_at?: string;
  isOpen?: boolean;
  isRead?: boolean;
  created_at: string;
  updated_at: string;
  __v: number;
}

export type ReadTypeField = 'all' | 'false'; // all => todas //\\ false => apenas não lidas
export interface IParsedContentNotification {
  type: NotificationsTypeNames;
  text_pt: string;
  text_en?: string;
  status?: string;
  is_active: boolean;
}

const DropdownNotification: React.FC = () => {
  const [notifications, setNotifications] = useState<
    Array<INotificationsProps>
  >([]);

  const {
    location: { key },
  } = useHistory();

  const { keycloak, initialized } = useKeycloak();

  const [unreadNotificationsCount, setUnreadNotificationsCount] = useState(0);

  const [currentTime, setCurrentTime] = useState(new Date());

  const { get, patch, response } = useFetch('/notification');

  const readNotification = useCallback(
    async (id: string) => {
      await patch(`/read/${id}`);
      setUnreadNotificationsCount(prev => --prev);
    },
    [patch],
  );

  const readAllNotifications = useCallback(
    async () => patch('/read-all'),
    [patch],
  );

  const getNotifications = useCallback(
    async (read: ReadTypeField = 'all') => {
      const params = {
        deleted: false,
        read,
      };

      await get(`/?${StringHelpers.encodeToQueryParams(params)}`);

      if (response.ok) {
        setUnreadNotificationsCount(response.data.unread);

        const notificationsCloneProp: Array<INotificationsProps> =
          response.data.docs.map((notification: INotificationsProps) => {
            return {
              ...notification,
              isOpen: false,
              isRead: notification.read_at !== null,
            };
          });
        setNotifications(notificationsCloneProp);
      } else {
        toast.error('Erro ao carregar as notificações!');
      }
    },
    [get, response],
  );

  const handleNotification = useCallback(
    (notification: string) => {
      notificationAlert(notification);
      getNotifications();
    },
    [getNotifications],
  );

  useEffect(() => {
    const socket = io(`${process.env.REACT_APP_BACKEND_URL}`, {
      transports: ['websocket'],
      auth: {
        authorization: `Bearer ${keycloak?.token}`,
      },
    });

    socket.on('System', (notification: string) => {
      handleNotification(notification);
    });

    return () => {
      socket.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keycloak, notifications]);

  useEffect(() => {
    if (keycloak && initialized) {
      getNotifications();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keycloak, initialized]);

  const openAndCloseNotification = useCallback(
    (id: string) => {
      const cloneNotification = notifications.map(
        (notification): INotificationsProps => {
          return {
            ...notification,
            isOpen: id === notification._id ? !notification.isOpen : false,
            isRead: (() => {
              if (id === notification._id) {
                if (!notification.isRead) {
                  readNotification(notification._id);
                }
                return true;
              }
              return notification.isRead;
            })(),
          } as INotificationsProps;
        },
      );
      setNotifications(cloneNotification);
    },
    [notifications, readNotification],
  );

  const markAllAsRead = useCallback(() => {
    readAllNotifications();
    setUnreadNotificationsCount(0);
    const cloneNotifications = notifications.map(
      (item): INotificationsProps => {
        return { ...item, isRead: true } as INotificationsProps;
      },
    );

    setNotifications(cloneNotifications);
  }, [notifications, readAllNotifications]);

  return (
    <Dropdown
      triggerToClose={key}
      toggle={
        <>
          <BsBell
            onClick={() => setCurrentTime(new Date())}
            size={24}
            color="var(--black)"
          />
          {unreadNotificationsCount > 0 && (
            <Count>{unreadNotificationsCount}</Count>
          )}
        </>
      }
    >
      <DropdownHeader>
        <DropdownHeaderTitle>Notificações</DropdownHeaderTitle>
        <div>
          <DropdownHeaderActionsItem onClick={markAllAsRead}>
            <AiOutlineCheck />
            Marcar todas como lidas
          </DropdownHeaderActionsItem>
        </div>
      </DropdownHeader>
      <NotificationsList
        currentTime={currentTime}
        onRead={openAndCloseNotification}
        notifications={notifications.slice(0, MAX_NOTIFICATIONS_IN_DROPDOWN)}
      />
    </Dropdown>
  );
};

export default DropdownNotification;
