import { GenericHeader, IHeaderProps, UserAvatar } from 'devyx-ui';
import React from 'react';
import { BsDisplay } from 'react-icons/bs';
import { FiBarChart2, FiPackage, FiUsers } from 'react-icons/fi';
import { GiOrganigram } from 'react-icons/gi';
import { GoGraph } from 'react-icons/go';
import { GrAction, GrWorkshop } from 'react-icons/gr';
import {
  MdBusinessCenter,
  MdLocalOffer,
  MdOutlinePointOfSale,
} from 'react-icons/md';
import {
  RiDashboardLine,
  RiFilePaperLine,
  RiListSettingsLine,
} from 'react-icons/ri';
import { Link, useLocation } from 'react-router-dom';
import blank from '../../../assets/blank.png';
import devyx from '../../../assets/devyx.png';
import { useAuth } from '../../../hooks/AuthContext';
import DropdownConfigs from '../DropdownConfigs';
import DropdownMenu from '../DropdownMenu';
import DropdownNotification from '../DropdownNotification';
import DropdownProfile from '../DropdownProfile';

const Header: React.FC = () => {
  const { pathname } = useLocation();
  const { authData } = useAuth();

  const avatarURL = `${process.env.REACT_APP_AVATAR_URL}/${authData.keycloackId}_mini.png?${authData.updated_at}`;

  const headerProps: IHeaderProps = {
    pathname,
    logoComponent: (
      <Link to="/">
        <img src={devyx} alt="Devyx" />
      </Link>
    ),
    menu: [
      {
        type: 'link',
        urlToShowActive: '/',
        menuItem: <Link to="/">Dashboard</Link>,
      },
      {
        type: 'link',
        urlToShowActive: '/users',
        menuItem: <Link to="/users">Usuários</Link>,
      },
      {
        type: 'link',
        urlToShowActive: '/companies',
        menuItem: <Link to="/companies">Companhias</Link>,
      },
      {
        type: 'dropdown',
        urlToShowActive: '/products',
        menuItem: (
          <DropdownMenu.Base title="Produtos">
            <DropdownMenu.Item to="/products/softwares">
              <BsDisplay />
              Softwares
            </DropdownMenu.Item>
            <DropdownMenu.Item to="/products/plans">
              <GoGraph /> Planos
            </DropdownMenu.Item>
            <DropdownMenu.Item to="/products/offers">
              <MdLocalOffer /> Ofertas
            </DropdownMenu.Item>
          </DropdownMenu.Base>
        ),
      },
      {
        type: 'dropdown',
        urlToShowActive: '/reports',
        menuItem: (
          <DropdownMenu.Base title="Relatórios">
            <DropdownMenu.Item to="/reports/subscriptions">
              <GrAction /> Assinaturas
            </DropdownMenu.Item>
            <DropdownMenu.Item to="/reports/actions">
              <GrWorkshop /> Ações
            </DropdownMenu.Item>
            <DropdownMenu.Item to="/reports/sales">
              <MdOutlinePointOfSale /> Vendas
            </DropdownMenu.Item>
          </DropdownMenu.Base>
        ),
      },
    ],
    actions: [
      {
        id: 'notification',
        showInDesktop: true,
        showInMobile: true,
        actionItem: <DropdownNotification />,
      },
      {
        id: 'config',
        showInDesktop: true,
        showInMobile: false,
        actionItem: <DropdownConfigs />,
      },
    ],
    profile: {
      id: 'profile',
      profileItem: (
        <DropdownProfile
          user={{
            name: authData.name,
            email: authData.email,
            avatar: authData.avatar,
            avatarURL,
          }}
          toggle={
            <>
              <span>{authData.name}</span>
              <UserAvatar
                defaultImage={blank}
                hasAvatar={authData.avatar}
                imageUrl={avatarURL}
              />
            </>
          }
        />
      ),
    },
    canvasMenu: {
      pathname,
      menuLink: [
        {
          urlToShowActive: '/',
          menuItem: (
            <>
              <Link to="/">
                <RiDashboardLine size={18} />
                Dashboard
              </Link>
            </>
          ),
        },
        {
          urlToShowActive: '/users',
          menuItem: (
            <>
              <Link to="/users">
                <FiUsers size={18} />
                Usuários
              </Link>
            </>
          ),
        },
        {
          urlToShowActive: '/companies',
          menuItem: (
            <>
              <Link to="/companies">
                <MdBusinessCenter size={18} />
                Companhias
              </Link>
            </>
          ),
        },
      ],
      menuExpansible: [
        {
          id: 'product',
          title: 'Produtos',
          urlToShowActive: 'products',
          icon: <FiPackage size={18} />,
          expansiveItens: [
            {
              id: 'software',
              urlToShowActive: '/products/softwares',
              menuItem: (
                <Link to="/products/softwares">
                  <BsDisplay size={16} />
                  Softwares
                </Link>
              ),
            },
            {
              id: 'plan',
              urlToShowActive: '/products/plans',
              menuItem: (
                <Link to="/products/plans">
                  <GoGraph size={16} />
                  Planos
                </Link>
              ),
            },
            {
              id: 'offer',
              urlToShowActive: '/products/offers',
              menuItem: (
                <Link to="/products/offers">
                  <MdLocalOffer size={16} />
                  Ofertas
                </Link>
              ),
            },
          ],
        },
        {
          id: 'report',
          title: 'Relatórios',
          urlToShowActive: 'reports',
          icon: <RiFilePaperLine size={18} />,
          expansiveItens: [
            {
              id: 'subscription',
              urlToShowActive: '/reports/subscriptions',
              menuItem: (
                <Link to="/reports/subscriptions">
                  <GrAction size={16} />
                  Assinaturas
                </Link>
              ),
            },
            {
              id: 'action',
              urlToShowActive: '/reports/actions',
              menuItem: (
                <Link to="/reports/actions">
                  <GrWorkshop size={16} />
                  Ações
                </Link>
              ),
            },
            {
              id: 'sale',
              urlToShowActive: '/reports/sales',
              menuItem: (
                <Link to="/reports/sales">
                  <MdOutlinePointOfSale size={16} /> Vendas
                </Link>
              ),
            },
          ],
        },
      ],
      configs: [
        {
          id: 'level',
          urlToShowActive: '/configurations/levels',
          configItem: (
            <Link to="/configurations/levels">
              <FiBarChart2 size={18} />
              Níveis
            </Link>
          ),
        },
        {
          id: 'category',
          urlToShowActive: '/configurations/categories',
          configItem: (
            <Link to="/configurations/categories">
              <GiOrganigram size={18} />
              Categorias
            </Link>
          ),
        },
        {
          id: 'option',
          urlToShowActive: '/configurations/options',
          configItem: (
            <Link to="/configurations/options">
              <RiListSettingsLine size={18} />
              Opções
            </Link>
          ),
        },
      ],
    },
  };

  return <GenericHeader {...headerProps} />;
};

export default Header;
