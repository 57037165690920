import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 14px;
  align-items: left;
`;

export const DropdownConfigsItem = styled(Link)`
  display: grid;
  grid-template-columns: 40px 1fr;

  text-decoration: none;
  font-family: Roboto, Barlow, open-sans;
  font-size: 16px;
  font-weight: 400;
  color: var(--black);
  transition: 0.5s;

  &:hover {
    color: var(--black);
    opacity: 0.6;
  }

  svg {
    width: 20px;
    height: 20px;
  }
`;
