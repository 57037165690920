import React from 'react';
import {
  DefaultNotification,
  IDefaultNotificationProps,
} from '../../DefaultNotification';
import { HelpText, TextContainer } from '../../DefaultNotification/styles';

export const UpdateSubscriptionNotification: React.FC<
  IDefaultNotificationProps
> = ({ ...notification }) => {
  return (
    <TextContainer>
      {notification.is_active ? (
        <HelpText>A assinatura está ativa</HelpText>
      ) : (
        <HelpText>A assinatura está desativada</HelpText>
      )}
      <DefaultNotification
        {...notification}
        created_at={notification.created_at}
      />
    </TextContainer>
  );
};
