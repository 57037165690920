import styled, { css } from 'styled-components';

interface IItemProps {
  isRead?: boolean;
  isOpen?: boolean;
}

export const Notifications = styled.ul`
  display: flex;
  flex-direction: column;
  max-height: 270px;
  overflow-y: scroll;
  flex: 1;
  width: 100%;

  ::-webkit-scrollbar {
    width: 12px;
  }

  ::-webkit-scrollbar-track {
    background: var(--white);
  }

  ::-webkit-scrollbar-thumb {
    background-color: var(--black);
    border-radius: 20px;
    border: 3px solid var(--white);
  }
`;

export const Item = styled.li`
  list-style: none;
  display: flex;
`;

export const NotificationAlertContainer = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-right: 6px;
  }
`;

export const ItemBody = styled.div<IItemProps>`
  ${props =>
    props.isOpen
      ? css`
            display: flex;
            align - items: center;
            max - height: 150px;

            > div {
                display: grid;
            }`
      : css`
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          padding: 5px 0px;
        `}
`;

export const ItemHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 0px;
  justify-content: space-between;

  @media (max-width: 700px) {
    flex-direction: column;
    gap: 8px;
  }
`;

export const ItemContent = styled.div<IItemProps>`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-bottom: 2px;
  cursor: pointer;
  border-radius: 8px;
  padding: 0 6px;
  transition: 300ms;

  &:hover {
    background-color: rgba(0, 113, 188, 0.1);
  }

  ${props =>
    props.isRead
      ? css`
          background: none;
        `
      : css`
          background-color: rgba(0, 113, 188, 0.1);
        `}

  :last-child {
    margin-bottom: none;
  }
`;

export const BulletUnreadItem = styled.span`
  display: flex;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  margin: auto;
  margin-right: 1px;
  background-color: var(--blue);
`;

export const ItemTitle = styled.span`
  margin: 0.3rem 0;
  font-size: 16px;
  font-weight: 600;
`;

export const ItemEmpty = styled.div`
  font-size: 20px;
  align-self: center;
  padding: 15px 0px;
  svg {
    margin: 5px 7px 0px 0px;
  }
  @media (max-width: 700px) {
    padding: 10px 15px;
  }
`;
