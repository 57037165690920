import React, { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { CrudContext } from 'devyx-ui';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import Routes from './routes';
import GlobalStyle from './styles/global';
import {
  IsKeycloakInitialized,
  keycloakInstance,
  keycloakConfig,
} from './helpers/auth';
import SplashScreen from './components/layout/SplashScreen';
import AuthProvider from './hooks/AuthContext';
import { StyledToastContainer } from './components/layout/StyledToastContainer';

const App: React.FC = () => {
  return (
    <>
      <StyledToastContainer position="bottom-right" />
      <Suspense fallback={<SplashScreen />}>
        <ReactKeycloakProvider
          authClient={keycloakInstance}
          initOptions={keycloakConfig}
          autoRefreshToken
        >
          <IsKeycloakInitialized>
            <BrowserRouter>
              <CrudContext.CrudProvider>
                <AuthProvider>
                  <Routes />
                </AuthProvider>
              </CrudContext.CrudProvider>
            </BrowserRouter>
            <GlobalStyle />
          </IsKeycloakInitialized>
        </ReactKeycloakProvider>
      </Suspense>
    </>
  );
};

export default App;
