import React from 'react';
import {
  DefaultNotification,
  IDefaultNotificationProps,
} from '../../DefaultNotification';
import { InvoiceNames, statusMessages } from './helpers';
import { StatusText } from './styles';

export interface IExpiredInvoiceNotificationProps
  extends IDefaultNotificationProps {
  status: InvoiceNames;
}

export const ExpiredInvoicesNotification: React.FC<
  IExpiredInvoiceNotificationProps
> = ({ status, ...rest }) => {
  return (
    <DefaultNotification {...rest}>
      <StatusText>
        <u>Status:</u> <b>{statusMessages[status]}</b>
      </StatusText>
    </DefaultNotification>
  );
};
